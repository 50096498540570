<template>
    <div class="attachments">
        <div class="attachments__wrapper ma-6">
            <div class="attachments-wrapper__title font-weight-bold text--secondary" v-if="currentDocument">
                {{ currentDocument.documentName }}
            </div>
            <hr class="mb-4" />
            <div class="attachments-wrapper__title font-weight-bold mb-4">
                {{ $t("attachments.title") }}
            </div>
            <Alerts v-if="!uploadedFiles.length && showAlert" :alertType="alertType" :alertMsg="alertMsg" />

            <div v-for="(file, index) in uploadedFiles" :key="index" class="custom-dropzone__file-item">
                <div class="file-name overflow-hidden">
                    <span class="bullet"></span>
                    <span class="text-truncate">{{ getTrimmedFileName(file.filename) }}</span>
                </div>
                <div>
                    <v-icon @click="removeFile(index)">mdi-delete</v-icon>
                    <!-- <a @click="downloadFile(index)"><v-icon>mdi-download</v-icon></a> -->
                </div>
            </div>
            <div class="attachments__dropzone-title font-weight-bold">
                {{ $t("attachments.doc-list-title") }}
            </div>
            <div class="attachments__dropzone-area mt-3">
                <custom-dropzone-component @files="uploadFiles" />
            </div>



        </div>
        <div class="footer-message">
            <div>
                <v-row>
                    <v-container class="py-0 fill-height">
                        <div class="col-md-12 col-12 reject pr-0 mt-6 text-center">
                            <v-btn large color="#f36e21" class="sign-reject-btn w-100" block dark @click="save()">
                                {{ $t("attachments.button-text") }}
                            </v-btn>
                        </div>
                    </v-container>
                </v-row>
            </div>
        </div>
        <default-loading v-if="loading" :loading="loading" />
    </div>
</template>

<script>
import CustomDropzoneComponent from './shared/Dropzone.component.vue'
import UploadService from '../services/upload-service.js'
import DefaultLoading from "./shared/DefaultLoading.component"
import Alerts from "../components/shared/Alerts.component";
import { mapGetters } from 'vuex';

export default {
    components: {
        CustomDropzoneComponent,
        DefaultLoading,
        Alerts
    },
    data() {
        return {
            loading: false,
            uploadedFiles: [],
            showAlert: false,
            alertType: "",
            alertMsg: "",
            token: null,
            authId: null,
            currentDocument: null,
        }
    },
    computed: {
        getTrimmedFileName() {
            return (fileName) => {
                const maxLength = 100
                if (fileName.length > maxLength) {
                    const trimmedName = fileName.substring(0, maxLength - 3) + '...';
                    return trimmedName
                }
                return fileName
            }
        },
        hasSignerForm() {
            return this.$store.getters['forms/hasSignerForm']
        },
        hasPendingSignerForm() {
            return this.$store.getters['forms/hasPendingSignerForm']
        },
        hasAssociation() {
            return this.$store.getters['common/hasAssociation']
        },
        currentRoute() {
            return this.$route;
        },
        documentCheckList() {
            return localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
        },
        ...mapGetters('attachments', [
            'hasAttachments', 'getTransactionApiToken'
        ]),
        ...mapGetters('forms', [
            'hasSignerForm', 'hasPendingSignerForm'
        ])
    },
    methods: {
        async loadAttachments() {
            if (this.token) {
                const attachments = await UploadService.getAttachments(this.$route.params.id, this.$route.query.token, this.$route.query.auth_id);
                this.uploadedFiles = attachments.data
            }
        },
        setAlert(show, alertType, alertMsg) {
            this.showAlert = show;
            this.alertType = alertType;
            this.alertMsg = alertMsg;
        },
        async save() {
            try {
                await this.loadFormStatus(); // Wait for loadFormStatus to complete

                if (this.uploadedFiles.length > 0) {
                    // Check for any signer forms
                    if (this.hasSignerForm && this.hasPendingSignerForm) {
                        this.$router.push({
                            name: `${this.hasAssociation ? 'custom-' : ''}forms`,
                            query: {
                                token: this.$route.query.token,
                                auth_id: this.$route.query.authId
                            }
                        });
                    } else if (!this.hasPendingSignerForm && !this.hasSignerForm) {
                        this.$router.push({
                            name: `${this.hasAssociation ? 'custom-' : ''}pdf-viewer`,
                            query: {
                                token: this.$route.query.token,
                                auth_id: this.$route.query.authId
                            }
                        });
                    } else if (!this.hasPendingSignerForm && this.hasSignerForm) {
                        this.$router.push({
                            name: `${this.hasAssociation ? 'custom-' : ''}pdf-viewer`,
                            query: {
                                token: this.$route.query.token,
                                auth_id: this.$route.query.authId
                            }
                        });
                    } else {
                        this.$router.push({
                            name: `${this.hasAssociation ? 'custom-' : ''}pdf-viewer`,
                            query: {
                                token: this.$route.query.token,
                                auth_id: this.$route.query.authId
                            }
                        });
                    }
                } else {
                    this.setAlert(true, 'error', this.$t("attachments.upload-required-message"));
                }
            } catch (error) {
                console.error('Error in save method:', error);
            }
        },
        async uploadFiles(files) {
            this.loading = true
            const response = await UploadService.uploadAttachments(this.$route.params.id, files, this.$route.query.token, this.$route.query.auth_id)
            this.uploadedFiles.push(response.data)
            this.loading = false

        },
        async downloadFile(index) {
            this.loading = true
            const response = await UploadService.downloadAttachments(this.$route.params.id, this.uploadedFiles[index].id, this.token, this.authId)
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = 'document.pdf'
            link.click()
            URL.revokeObjectURL(url)
            this.loading = false
        },
        async removeFile(index) {
            this.loading = true
            await UploadService.removeAttachments(this.$route.params.id, this.uploadedFiles[index].id, this.token, this.authId)
            this.uploadedFiles.splice(index, 1)
            this.loading = false
        },
        findCurrentDocument() {
            // let currentDoc = this.documentCheckList.filter((document) => document.documentId == this.currentRoute.params.id);
            // this.currentDocument = currentDoc && currentDoc.length ? currentDoc[0] : null;
            this.loadAttachments();

        },
        async loadFormStatus() {
            try {
                const response = await this.$store.dispatch('forms/getFormStatus', {
                    requestId: this.$route.params.id,
                    token: this.token,
                    authId: this.authId
                });
                return response;
            } catch (error) {
                console.error('Error loading form status:', error);
                throw error; // Re-throw the error to be caught in the save method
            }
        }
    },
    mounted() {
   
        // if (this.currentRoute && this.currentRoute.params.id) {
        this.findCurrentDocument()
        // }
    },
    created() {
        this.token = this.$route.query.token || null;
        this.authId = this.$route.query.auth_id || null;
    }
}
</script>

<style scoped>
.attachments__wrapper {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
}

.attachments-wrapper__title {
    font-size: 18px;
    margin-bottom: 10px;
}

.custom-dropzone__file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    background: rgba(243, 110, 33, 0.1);
    /* Light background color derived from theme color */
    border: 1px solid #ddd;
    border-radius: 5px;
}

.custom-dropzone__file-item v-icon {
    cursor: pointer;
}

.file-name {
    display: flex;
    align-items: center;
}

.bullet {
    width: 10px;
    height: 10px;
    background-color: #f36e21;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.custom-dropzone__file-item {
    display: flex;
    justify-content: space-between;
}
</style>